<template>
    <div class="portal-system-dept">
        <div class="portal-system-dept-wrapper">
            <div class="portal-system-dept-header">
                <div>
                    <div class="portal-system-dept-header-title"> {{ $t('systemInfo.dept') }} </div>
                </div>
                <div>
                    <cgs-button ghost style="margin-right: 10px;"
                        @click="state.deptLabelVisible = true">部门标签管理</cgs-button>
                    <cgs-button ghost @click="addDeptList">
                        <template #icon>
                            <span class="cgs-portal-iconfont cgs-portal-icon-tianjia portal-system-dept-icon"></span>
                        </template>
                        {{ $t('systemInfo.addDept') }}</cgs-button>
                </div>
            </div>
            <div class="portal-system-dept-content-container">
                <div class="portal-system-dept-tree-container">
                    <cgs-tree class="portal-system-dept-tree" :tree-data="state.treeData" :draggable="true"
                        v-model:expandedKeys="state.expandedKeys" v-model:selectedKeys="state.selectedKeys"
                        :field-names="state.replaceFields" @select="onChangeDept" @drop="onDrop">
                        <template #title="record">
                            <div class="portal-system-dept-title">
                                <div class="portal-system-dept-title-label">{{ record.label }}</div>
                                <div class="portal-system-dept-title-icon">
                                    <a-tooltip placement="top">
                                        <template #title>
                                            <span>{{ $t('systemInfo.addSubDept') }}</span>
                                        </template>
                                        <span
                                            class="portal-system-dept-title-icon-item cgs-portal-iconfont cgs-portal-icon-tianjia clickable-icon"
                                            @click="actionMenu('add', record)"></span>
                                    </a-tooltip>
                                    <a-tooltip placement="top">
                                        <template #title>
                                            <span>{{ $t('common.edit') }}</span>
                                        </template>
                                        <span
                                            class="portal-system-dept-title-icon-item cgs-portal-iconfont cgs-portal-icon-xiugai clickable-icon"
                                            @click="actionMenu('edit', record)"></span>
                                    </a-tooltip>
                                    <a-tooltip placement="top">
                                        <template #title>
                                            <span>{{ $t('common.delete') }}</span>
                                        </template>
                                        <span
                                            class="portal-system-dept-title-icon-item cgs-portal-iconfont cgs-portal-icon-shanchu clickable-icon"
                                            @click="actionMenu('delete', record)"></span>
                                    </a-tooltip>
                                </div>
                            </div>
                        </template>
                    </cgs-tree>
                </div>
                <div class="portal-system-dept-content">
                    <div class="portal-system-dept-content-table">
                        <deptTable :sceneList="showDatasource" @searchInput="searchInput"></deptTable>
                    </div>
                    <div class="portal-system-dept-content-footer">
                        <cgs-pagination v-model:current="state.currentPagination.current"
                            v-model:pageSize="state.currentPagination.pageSize" show-quick-jumper show-size-changer
                            :total="total" :show-total="total => $t('common.paginationTotal', { 'num': total })" />
                    </div>
                </div>
            </div>

        </div>
        <addDeptModal :dialogVisible="state.dialogVisible" :dialogTitle="state.dialogTitle" :formData="state.formData"
            :userTableData="state.userTableData" @cancelAddDeptModal="cancelAddDeptModal" @addDeptModal="addDeptModal">
        </addDeptModal>
        <deptLabelModal :dialogVisible="state.deptLabelVisible" @cancelAddDeptModal="state.deptLabelVisible = false" />
    </div>
</template>

<script>
import {
    defineComponent,
    reactive,
    getCurrentInstance,
    computed
} from 'vue'
import {
    useI18n
} from 'vue-i18n'
import {
    getDeptList,
    getRoleList,
    getDeptUserList,
    addDept,
    deleteDept,
    updateDept,
    getJobList
} from '@/api/tenant'
import deptTable from './components/dept-table.vue'
import addDeptModal from './components/addDept.vue'
import deptLabelModal from './components/deptLabelModal.vue'
// import cascaderMenu from '@/components/cascaderMenu.vue'

export default defineComponent({
    components: {
        deptTable,
        addDeptModal,
        deptLabelModal
        // cascaderMenu
    },
    props: {},
    setup() {
        let {
            proxy
        } = getCurrentInstance();
        const { t } = useI18n()

        const state = reactive({
            searchInput: '',
            treeData: [],
            roleNameMap: [],
            switchDeptMenu: '',
            userTableData: [],

            dialogVisible: false,
            dialogTitle: '添加部门',
            parentID: '',
            actionMenuType: '',

            currentPagination: {
                current: 1,
                pageSize: 20,
            },
            selectedKeys: [],
            expandedKeys: [],
            replaceFields: {
                title: 'label',
                key: 'id',
            },

            formData: {
                parentID: 0,
                parentLabel: '',
                property: {}
            },
            deptLabelVisible: false
        })
        let actionMenuList = [{
            title: '添加子部门',
            key: 'add',
            icon: 'cgs-portal-iconfont cgs-portal-icon-tianjia'
        },
        {
            title: '编辑',
            key: 'edit',
            icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
        },
        {
            title: '删除',
            key: 'delete',
            icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
        }
        ]
        const createDeptTree = (deptList = []) => {
            var m = {};
            deptList.forEach(d => {
                var pId = d.parentId
                if (!m[pId]) {
                    m[pId] = []
                }
                m[pId].push(d)
            });
            var pArr = m["0"]
            var tree = []
            if (!pArr) return tree;
            pArr.forEach(p => {
                var dept = {
                    label: p.name,
                    value: p.id,
                    id: p.id,
                    parentID: p.parentId,
                    labelIds: p.labelIds,
                    property: p.property ? p.property : {},
                    children: getChildren(p, m)
                }
                tree.push(dept)
            })
            return tree;

        }

        const onDrop = info => {
            const dropKey = info.node.key;
            const dragKey = info.dragNode.key;
            const dropPos = info.node.pos.split('-');
            const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
            const loop = (data, key, callback) => {
                data.forEach((item, index) => {
                    if (item.id === key) {
                        return callback(item, index, data);
                    }
                    if (item.children) {
                        return loop(item.children, key, callback);
                    }
                });
            };
            const data = JSON.parse(JSON.stringify(state.treeData));
            // Find dragObject
            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
                // dragObj.parentId = info.node.id
            });
            let parentId = "0"

            if (!info.dropToGap) {
                // Drop on the content
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    /// where to insert 示例添加到头部，可以是随意位置
                    item.children.unshift(dragObj);
                    parentId = item.id
                });
            } else if ((info.node.children || []).length > 0 &&
                // Has children
                info.node.expanded &&
                // Is expanded
                dropPosition === 1 // On the bottom gap
            ) {
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    // where to insert 示例添加到头部，可以是随意位置
                    item.children.unshift(dragObj);
                    parentId = item.id
                });
            } else {
                let ar = [];
                let i = 0;
                loop(data, dropKey, (_item, index, arr) => {
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) {
                    ar.splice(i, 0, dragObj);
                } else {
                    ar.splice(i + 1, 0, dragObj);
                }
            }
            state.treeData = data
            updateDept({
                Name: info.dragNode.label,
                ParentID: parentId,
                id: dragKey,
            }).then(res => {
                if (res.code === 200) {
                    proxy.$message.success('修改成功')
                } else {
                    proxy.$message.error('修改失败')
                }
            })
        }
        const getChildren = (p, m) => {
            var id = p.id
            var childs = m[id];
            if (!childs) return [];
            var children = []
            childs.forEach(c => {
                var d = {
                    label: c.name,
                    value: c.id,
                    id: c.id,
                    parentID: c.parentId,
                    labelIds: c.labelIds,
                    property: c.property ? c.property : {},
                    children: getChildren(c, m)
                }
                children.push(d)

            })

            return children;
        }
        const jobMap = {};
        const getDeptData = () => {
            getDeptList().then((res) => {
                let depArr = res.data;
                let tree = createDeptTree(depArr)
                state.treeData = tree;
                if (state.selectedKeys.length === 0 && state.treeData.length > 0) {
                    onChangeDept(state.treeData[0].id)
                }
            })

            getRoleList({
                type: "BACK_ROLE"
            }).then((res) => {
                if (res && res.code === 200) {

                    let m = {};

                    for (let i = 0; i < res.data.length; i++) {
                        m[res.data[i].id] = res.data[i].roleName;

                        state.roleNameMap = m;
                    }
                }
            })
            getJobList().then(jobRes => {
                if (Array.isArray(jobRes.data)) {
                    jobRes.data.forEach((item) => {
                        jobMap[item.id] = item.name;
                    });
                }
            })
        }
        const clickMenu = (item) => {
            onChangeDept(item.id);
        }
        const actionMenu = (type, item) => {
            if (type === 'add') {
                state.dialogTitle = t('systemInfo.addSubDept')
                state.dialogVisible = true
                state.formData.parentID = item.id
                state.actionMenuType = type
                state.formData.parentLabel = ''
                state.formData.property = {}
                state.formData.id = ''
                addExpandedKey(item.id)
            } else if (type === 'edit') {
                state.dialogTitle = t('systemInfo.editDept')
                state.dialogVisible = true
                state.formData.id = item.id
                state.formData.parentID = item.parentID
                state.formData.parentLabel = item.label
                state.actionMenuType = type
                state.formData.property = item.property
                state.formData.labelIds = item.labelIds
            } else if (type === 'delete') {
                proxy.$confirm({
                    title: t('common.remind'),
                    content: t('systemInfo.deleteDeptRemind'),
                    async onOk() {
                        deleteDept({
                            deptId: item.id
                        }).then(res => {
                            if (res.code != 200) {
                                proxy.$message.error(res.msg)
                                return;
                            }
                            proxy.$message.success(t('common.deleteSuccess'))
                            getDeptData();
                        });
                    },
                    onCancel() { },
                });
            }
        }

        const onChangeDept = (d) => {
            let deptId
            if (d instanceof Array) {
                deptId = d[0];
            } else {
                deptId = d;
            }
            getDeptUserList({
                deptId: deptId
            }).then((res) => {
                if (res && res.success) {
                    state.userTableData = [];
                    for (let i = 0; i < res.data.length; i++) {
                        let user = res.data[i];
                        let jobs = [];
                        if (user.jobIds) {
                            jobs = user.jobIds.map((id) => jobMap[id]).filter(Boolean);
                        }
                        // console.log(res.data.data)
                        state.userTableData.push({
                            userId: user.userId,
                            userRole: state.roleNameMap[user.roleId],
                            userName: user.username,
                            userAlia: user.name,
                            userDepts: user.depts,
                            isDirector: !!user.isDirector,
                            jobs: jobs.join(', '),
                        })
                    }
                }
            })
        }
        const addDeptList = () => {
            state.dialogTitle = t('systemInfo.addDept')
            state.dialogVisible = true
            state.formData.parentID = '0'
            state.formData.parentLabel = ''
            state.formData.property = {},
                state.formData.id = ''
            state.actionMenuType = 'add'
        }
        const cancelAddDeptModal = () => {
            state.dialogVisible = false
        }
        const addDeptModal = (values, directorIds) => {
            const result = {
                Name: values.deptName,
                ParentID: values.parentID,
                property: values.property,
                labelIds: values.labelIds,
                directorIds
            }
            if (state.actionMenuType === 'add') {
                addDept(result).then(res => {
                    state.dialogVisible = false
                    if (res.code === 200) {
                        proxy.$message.success(t('common.addSuccess'))
                        getDeptData();
                        state.parentID = ''
                    } else {
                        proxy.$message.error(t('common.addFail'))
                    }
                })
            } else if (state.actionMenuType === 'edit') {
                result.id = values.id
                updateDept(result).then(res => {
                    if (res.code === 200) {
                        state.dialogVisible = false;
                        proxy.$message.success(t('common.modifySuccess'))
                        getDeptData();
                        state.parentID = ''
                    } else {
                        proxy.$message.error(t('common.modifyFail'))
                    }
                })
            }
        }
        const searchInput = (value) => {
            state.currentPagination.current = 1
            state.searchInput = value
        }
        const showDatasource = computed(() => {
            let _pagination = state.currentPagination
            return sceneData.value.filter((item, index) => {
                return index >= (_pagination.pageSize * (_pagination.current - 1)) &&
                    index < _pagination.pageSize * _pagination.current
            })
        })
        const sceneData = computed(() => {
            let searchInput = state.searchInput ? state.searchInput : ''
            let sceneList = state.userTableData
            let filterData = sceneList.filter(data => !searchInput || data.userName.toLowerCase()
                .includes(
                    searchInput.toLowerCase()) || data.userAlia.toLowerCase().includes(
                        searchInput
                            .toLowerCase()))
            return filterData
        })
        const total = computed(() => {
            return sceneData.value.length
        })
        const addExpandedKey = (key) => {
            if (!state.expandedKeys.includes(key)) {
                state.expandedKeys.push(key)
            }
        }
        getDeptData()
        return {
            state,
            actionMenuList,
            showDatasource,
            sceneData,
            total,
            onDrop,
            addDeptList,
            getDeptData,
            onChangeDept,
            cancelAddDeptModal,
            addDeptModal,
            clickMenu,
            actionMenu,
            searchInput,
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-system-dept {
    width: 100%;
    height: 100%;
    padding: 24px 48px 8px 48px;
    overflow: hidden;
}

.portal-system-dept-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.portal-system-dept-header {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.portal-system-dept-content-container {
    flex: 1;
    height: 0;
    display: flex;
    flex-direction: row;
}

.portal-system-dept-header-title {
    .little-title;
    color: @font-color-1;
}

.portal-system-dept-content {
    flex: 1;
    width: 100%;
    // height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
}

.portal-system-dept-content-table {
    flex: 1;
    height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    .border()
}

.portal-system-dept-tree-container {
    width: 300px;
    margin-right: 24px;
    overflow: auto;
}

.portal-system-dept-content-footer {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portal-system-dept-icon {
    font-size: 10px;
    color: @font-color-1;
    margin-right: 5px;
}

.portal-system-dept-title {
    display: flex;
}

.portal-system-dept-title-label {
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.portal-system-dept-title-icon-item {
    margin: 0px 5px;
    font-size: 12px;
}
</style>

<style>
.portal-system-dept-tree.cgs-design-tree.ant-tree .ant-tree-treenode {
    width: 100%;
}

.portal-system-dept-tree.cgs-design-tree.ant-tree .ant-tree-node-content-wrapper {
    flex: 1;
}
</style>